import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"
// import styled from "styled-components"; 

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
// import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
// import ArticleSidebar from "../../components/content/ArticleSidebar"
// import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
// import SampleItineraries from "../../components/content/SampleItineraries"
// import PageSectionHeadline from "../../components/content/PageSectionHeadline"

// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Carousel from 'react-bootstrap/Carousel'
// import Button from 'react-bootstrap/Button'
// import Modal from 'react-bootstrap/Modal'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
// import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
// import headerimage from '../../images/headerimage-spiritualrestoration.jpg'




const PrivacyPolicy = () => {
    const pageData = {
                        headline:{
                            text:<>Website Privacy Policy</>,
                            id:'privacypolicy'
                        },
                        sectionnav:{section:'agreements'},
                        seo:{
                            title:'Website Privacy Policy',
                            description:'',
                            // image:'/images/headerimage-spiritualrestoration.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Privacy Policy',location); 
    },[location]);

    // const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    // function startConsultationRequest(which){ 
    //     GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
    //     setShowConsultationForm(true); 
    // }
    

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>

<p>Last updated September 8, 2021</p>

<p>We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at go@outward.travel.</p>

<p>This privacy notice describes how we might use your information if you:</p>
<ul>
	<li>Visit our website at outward.travel</li>
	<li>Visit any of our customer-branded co-branded websites which we control and on which our Logo appears</li>
	<li>Use any software or application we provide via outward.app</li>
	<li>Engage with us in other related ways ― including any sales, marketing, or events</li>
</ul>

<p>In this privacy notice, if we refer to:</p>
<ul>
	<li>"Website," we are referring to any website of ours that references or links to this policy</li>
  <li>"App," we are referring to any application of ours that references or links to this policy, including any listed above</li>
	<li>"Services," we are referring to our Website, App, and other related services, including any sales, marketing, or events</li>
	</ul>
	
<p>The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</p>

<p>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</p>

<h2>Information We Collect</h2>
<h3>Personal information you disclose to us</h3>

<p>In Short:  We collect personal information that you provide to us.</p>

<p>We collect personal information that you voluntarily provide to us when you complete and submit a form on our website, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.</p>

<p>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:</p>

<ul>
	<li><b>Personal Information Provided by You.</b> We collect names; email addresses; phone numbers; mailing addresses; job titles; usernames; passwords; contact preferences; contact or authentication data; billing addresses; debit/credit card numbers; and other similar information.</li>

	<li><b>Payment Data.</b> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data stored by us is encrypted and the decryption mechanism is not stored in the same software system. Payment data may be collected by a third party and funds forwarded to us, in which case we do not store your payment data at all.</li>

	<li><b>Social Media Login Data.</b> We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW WE HANDLE YOUR SOCIAL LOGINS" below.</li>
</ul>

<p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>

<h3>Information automatically collected</h3>

<p>In Short:  Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>

<p>We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

<p>Like many businesses, we also collect information through cookies and similar technologies.</p>

<p>The information we collect includes:</p>
<ul>
	<li><i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</li>
	
  <li><i>Device Data.</i> We collect device data such as information about your computer, phone, tablet or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.</li>
	
  <li><i>Location Data.</i> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
</ul>

<h2>How We Use Your Information</h2>

<p>In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>

<p>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>

<p>We use the information we collect or receive:</p>
<ul>
	<li>To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed "HOW  WE HANDLE YOUR SOCIAL LOGINS" for further information.</li>
	<li>Request feedback. We may use your information to request feedback and to contact you about your use of our Services.</li>
	<li>To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.</li>
	<li>To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.</li>
	<li>To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</li>
	<li>To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).</li>
	<li>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</li>
	<li>To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
	<li>Provide services. We use your information to provide our core business services as a travel agency.</li>
	<li>Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in our competitions.</li>
	
	<li>To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.</li>
	<li>To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the "YOUR PRIVACY RIGHTS?" below)</li>
	<li>Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>
</ul>

<h2>What Information We Share With Others</h2>

<p>In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>

<p>We may process or share your data that we hold based on the following legal basis:</p>
<ul>
	<li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
	<li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
	<li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
	<li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
	<li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
	</ul>
	
<p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
<ul>
    <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
</ul>

<h2>Use of Cookies and Tracking Technologies</h2>

<p>In Short:  We may use cookies and other tracking technologies to collect and store your information.</p>

<p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Disabling or deleting cookies stored in your browser may impact the quality of our Services. </p>

<h2>How We Handle Your Social Logins</h2>
<p>In Short:  If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</p>

<p>Our Services may offer you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.</p>

<p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>

<h2>How Long We Keep Information</h2>
<p>In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice or until you request deletion of personally identifiable information,  unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). If you choose to fully unsubscribe from all contact methods (e.g., email marketing or newsletters, unfollowing on social media, or by request), your information may be anonymized for later statistical analysis but will no longer be tied to your personally identifiable information.</p>

<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

<h2>How We Protect Your Information</h2>

<p>In Short:  We aim to protect your personal information through a system of organizational and technical security measures.</p>

<p>Information collected via our Services is stored and managed in our Customer Relationship Management (CRM) system. Data stored in our CRM system is encrypted at rest and encrypted in transit, and is only accessible via connections protected by Transport Layer Security (TLS). Access to data is regulated by user credentials and limited to authorized staff members or independent contractors of Outward Travel. </p>

<p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

<h2>We Do Not Knowingly Collect Data from Minors</h2>
<p>In Short:  We do not knowingly collect data from or market to children under 18 years of age.</p>

<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at go@outward.travel.</p>

<h2>Your Privacy Rights</h2>

<p>In Short:  You may review, change, or request deletion of stored information at any time.</p>

<p>You may, at any time, request an exported copy of the information associated with your personally identifiable information that Outward Travel has collected and stored. Outward Travel will provide this report within 45 days of the request. </p>

<p>You may, at any time, request that all or some of the information associated with your personally identifiable information be deleted by Outward Travel. We will need to verify your identity to determine you are the same person about whom we have information in our system, and will delete the information within 45 days of verification. Your information may be anonymized for future statistical analysis. We may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</p>

<p><b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. </p>

<p><b>Opting out of email marketing:</b> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>

<h2>Browsers' Do-Not-Track Features</h2>
<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. </p>

<h2>Specific Privacy Rights for California Residents</h2>

<p>In Short:  If you are a resident of California, you are granted specific rights regarding access to your personal information.</p>

<p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>

<p>If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>

<h3>CCPA Privacy Notice</h3>

<p>The California Code of Regulations defines a "resident" as:</p>
<ol>
<li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
<li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
</ol>

<p>All other individuals are defined as "non-residents."</p>

<p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>

<h4>What categories of personal information do we collect?</h4>

<p>If you have completed and submitted any form on our website, we may have collected and stored the following categories of personal information in the past 12 months:</p>

<ul>
	<li>Identifiers (Contact details such as name, alias, postal address, phone numbers, personal identifiers, online identifiers, Internet Protocol address, email address).</li>
	<li>Personal Information categories as listed in the California Customer Records statute (name, contact information, education, employment, employment history, financial information)
        <ul>
		<li><i>Note that our Services do not collect employment, education, employment history, or financial information other than payment methods of non-customers and very rarely do we collect this information for customers.</i></li>
        </ul>
    </li>
	<li>Protected classification characteristics under California or Federal Law (gender, date of birth)</li>
	<li>Internet or similar network activity (browser history, interactions with our website, systems or advertisement).</li>
	<li>Geolocation data</li>
	<li>Inferences drawn from other personal information (inferences drawn from any of the collected personal information above to create a profile or summary about, for example, an individual's preferences or characteristics). </li>
</ul>

<p>We do not collect the following categories of personal information as defined in California statutes:</p>
<ul>
	<li>Commercial Information (except for active customers of our Services)</li>
	<li>Biometric information</li>
	<li>Internet or other similar network activity for other websites</li>
	<li>Audio, electronic, visual, thermal, olfactory, or similar information</li>
	<li>Professional or employment-related information</li>
	<li>Education information</li>
</ul> 

<p>We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context of:</p>
<ul>
	<li>Receiving help through our customer support channels;</li>
	<li>Participation in customer surveys or contests; and</li>
	<li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
</ul>
<h4>How do we use and share your personal information?</h4>

<p>More information about our data collection and sharing practices can be found in this privacy notice.</p>

<p>You may contact us by email at go@outward.travel or by calling (720) 828-7665. </p>

<p>If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>

<h4>Will your information be shared with anyone else?</h4>

<p>We do not share your information with other parties or service providers. If you are a customer of Outward Travel (with a signed Booking Agreement incorporating the Terms and Conditions for Travel Services agreement), we will disclose the minimally required information in order to complete the Booking of your travel. </p>

<p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal data.</p>

<p>Rook Behr Ltd. has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 months. Rook Behr Ltd. will not sell personal information in the future belonging to website visitors, users and other consumers.</p>

<h4>Your rights with respect to your personal data</h4>

<p><b>Right to request deletion of the data - Request to delete</b></p>

<p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.</p>

<p><b>Right to be informed - Request to know</b></p>

<p>Depending on the circumstances, you have a right to know:</p>
<ul>
	<li>whether we collect and use your personal information;</li>
	<li>the categories of personal information that we collect;</li>
	<li>the purposes for which the collected personal information is used;</li>
	<li>whether we sell your personal information to third parties;</li>
	<li>the categories of personal information that we sold or disclosed for a business purpose;</li>
	<li>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>
	<li>the business or commercial purpose for collecting or selling personal information.</li>
</ul>

<p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>

<p><b>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</b></p>

<p>We will not discriminate against you if you exercise your privacy rights.</p>

<p><b>Verification process</b></p>

<p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>

<p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information within 45 days of verification.</p>

<p><b>Other privacy rights</b></p>
<ul>
<li>you may object to the processing of your personal data </li>
<li>you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data </li>
<li>you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA. </li>
<li>you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission. </li>
<li>To exercise these rights, you can contact us by email at go@outward.travel, by visiting outward.travel/about/contact, by calling (720) 828-7665, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.  </li>
</ul>

<h2>Updates to This Notice</h2>

<p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may but are not obligated to notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

<h2>Contacting Us About This Notice</h2>

<p>If you have questions or comments about this notice, you may email us at go@outward.travel.</p>


            </article>

        </Tier3Layout>
    </main>
  )
}


export default PrivacyPolicy;

